const ADMIN_NOTIFICATION_SUBSCRIPTION_RESET =
  "ADMIN_NOTIFICATION_SUBSCRIPTION_RESET";

const ACTION_ADMIN_NOTIFICATION_SUBSCRIPTION_RESET = () => {
  return {
    type: ADMIN_NOTIFICATION_SUBSCRIPTION_RESET,
  };
};

export default ACTION_ADMIN_NOTIFICATION_SUBSCRIPTION_RESET;
