const BACIAL_NOT_IN_CART = "BACIAL_NOT_IN_CART";

const ACTION_BACIAL_NOT_IN_CART = () => {
  return {
    type: BACIAL_NOT_IN_CART,
    payload: {
      name: "Bacial"
    }
  };
};

export default ACTION_BACIAL_NOT_IN_CART;
