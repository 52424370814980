import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
import { useSelector, useDispatch } from "react-redux";
import { Spring } from "react-spring/renderprops";
import SplashScreen from "./SplashScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import ACTION_BODY_SCROLL_ALLOW from "../../actions/Body_Scroll/ACTION_BODY_SCROLL_ALLOW";
import ACTION_USER_SCROLLED from "../../actions/Scroll/ACTION_USER_SCROLLED";
import ACTION_USER_SCROLLED_RESET from "../../actions/Scroll/ACTION_USER_SCROLLED_RESET";
import ACTION_NAVBAR_IS_VISIBLE from "../../actions/NavbarIsVisible/ACTION_NAVBAR_IS_VISIBLE";
import ACTION_SPLASH_SCREEN_COMPLETE from "../../actions/SplashScreenComplete/ACTION_SPLASH_SCREEN_COMPLETE";
import ACTION_SPLASH_SCREEN_HALFWAY from "../../actions/SplashScreenHalfway/ACTION_SPLASH_SCREEN_HALFWAY";
import ACTION_TOUCH_SCALING_ACTIVE from "../../actions/FingerTouchScaling/ACTION_TOUCH_SCALING_ACTIVE";
import ACTION_TOUCH_SCALING_RESET from "../../actions/FingerTouchScaling/ACTION_TOUCH_SCALING_RESET";
import ACTION_LOGIN_IS_NOT_ACTIVE from "../../actions/Login/ACTION_LOGIN_IS_NOT_ACTIVE";
import { useLocation } from "react-router-dom";
import "./LandingPage.css";

const LandingPage = React.forwardRef((props, ref) => {
  const { LandingPageRef } = ref;
  const {
    currentScreenSize,
    initialScreenSize,
    scrollValue,
    treatmentsPageInView,
    name,
    handleClickToScrollToTreatments,
    initialScreenHeight,
    currentScreenHeight,
  } = props;

  const [lineRenderScroll, setLineRenderScroll] = useState(false);
  const [twoFingerTouch, changeTwoFingerTouch] = useState(false);

  const location = useLocation();

  const navbarToggle = useSelector((state) => state.navbarToggle.toggle);
  const bodyScrollToggle = useSelector(
    (state) => state.bodyScrollToggle.overflow
  );
  const scroll = useSelector((state) => state.scrollToggle.scroll);
  const cartIsActive = useSelector((state) => state.cartIsActive.cartIsActive);
  const splashScreenComplete = useSelector(
    (state) => state.splashScreenComplete.splashScreenComplete
  );
  const splashScreenHalfway = useSelector(
    (state) => state.splashScreenHalfway.splashScreenHalfway
  );
  const touchScaling = useSelector(
    (state) => state.fingerTouchScaling.touch_scaling
  );
  const finalBookingModal = useSelector(
    (state) => state.finalBookingModal.final_booking_modal
  );

  // For comparison after splash screen halfway point
  const [
    currentOrientationSnapshot,
    changeCurrentOrientationSnapshot,
  ] = useState(null);

  const dispatch = useDispatch();

  const handleSplashScreenHalfway = (el) => {
    if (
      Number(el.top.substr(0, 3)) === 100 ||
      Number(el.right.substr(0, 3)) === 100
    ) {
      changeCurrentOrientationSnapshot(currentScreenSize);
    } else {
      if (!splashScreenHalfway) {
        dispatch(ACTION_SPLASH_SCREEN_HALFWAY());
      } else {
        if (currentOrientationSnapshot !== currentScreenSize) {
          dispatch(ACTION_SPLASH_SCREEN_COMPLETE());
          dispatch(ACTION_BODY_SCROLL_ALLOW());
        }
      }
    }
  };

  useEffect(() => {
    dispatch(ACTION_LOGIN_IS_NOT_ACTIVE());
  }, [dispatch]);

  useMemo(() => {
    if (location.pathname === "/") {
      dispatch(ACTION_NAVBAR_IS_VISIBLE());
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (splashScreenHalfway) {
      if (initialScreenSize >= 600) {
        if (currentOrientationSnapshot !== currentScreenSize) {
          dispatch(ACTION_SPLASH_SCREEN_COMPLETE());
          dispatch(ACTION_BODY_SCROLL_ALLOW());
        }
      }
    }
  }, [
    currentOrientationSnapshot,
    currentScreenSize,
    initialScreenSize,
    splashScreenHalfway,
    dispatch,
  ]);

  const changeScroll = useCallback(() => {
    const userScroll = !currentScreenSize
      ? initialScreenSize >= 600
        ? scrollValue < 50
        : scrollValue < 345
      : currentScreenSize >= 600
      ? scrollValue < 50
      : scrollValue < 345;

    if (!userScroll) {
      dispatch(ACTION_USER_SCROLLED());
    } else {
      dispatch(ACTION_USER_SCROLLED_RESET());
    }

    if (treatmentsPageInView) {
      setLineRenderScroll(true);
    } else {
      setLineRenderScroll(false);
    }
  }, [
    dispatch,
    treatmentsPageInView,
    currentScreenSize,
    initialScreenSize,
    scrollValue,
  ]);

  useEffect(() => {
    const preventScroll = (e) => e.preventDefault();

    if (bodyScrollToggle === "visible") {
      document.body.classList.remove("no_scroll");
      if (!navbarToggle) {
        document.body.classList.remove("no_scroll_no_fixed");
      }
      if (splashScreenComplete) {
        document.body.classList.add("scroll_reset");
      } else {
        document.body.classList.remove("scroll_reset");
        document.body.classList.add("no_scroll");
      }
    } else if (bodyScrollToggle === "hidden") {
      document.body.classList.remove("scroll_reset");
      if (!navbarToggle) {
        document.body.classList.add("no_scroll_no_fixed");
      } else {
        document.body.classList.add("no_scroll");
      }

      // Required for iOS Landscape Scroll Disabling During Splash Screen
      if (!splashScreenComplete) {
        if (LandingPageRef) {
          LandingPageRef.current.addEventListener(
            "touchmove",
            preventScroll,
            false
          );
          setTimeout(
            () =>
              LandingPageRef.current.removeEventListener(
                "touchmove",
                preventScroll,
                false
              ),
            initialScreenSize >= 600 ? 3800 : 2400
          );
        }
      }
    }
  }, [
    bodyScrollToggle,
    LandingPageRef,
    splashScreenComplete,
    initialScreenSize,
    currentScreenSize,
    navbarToggle,
    cartIsActive,
  ]);

  useEffect(() => {
    document.addEventListener("scroll", changeScroll);
    return () => {
      document.removeEventListener("scroll", changeScroll);
    };
  }, [scroll, changeScroll]);

  useEffect(() => {
    const LandingPageRefTargetElement = LandingPageRef.current;

    if (!splashScreenComplete) {
      const handleDisableScroll = (el) => {
        disableBodyScroll({ targetElement: el });
      };

      handleDisableScroll(LandingPageRefTargetElement);
    } else {
      if (!cartIsActive) {
        const handleEnableScroll = (el) => {
          if (splashScreenComplete && !cartIsActive) {
            enableBodyScroll({ targetElement: el });
          }
        };

        handleEnableScroll(LandingPageRefTargetElement);
      }
    }

    if (!splashScreenComplete) {
      let bodyScrollTimer = setTimeout(
        () => {
          dispatch(ACTION_BODY_SCROLL_ALLOW());
          dispatch(ACTION_SPLASH_SCREEN_COMPLETE());
        },
        !currentScreenSize
          ? initialScreenSize >= 600
            ? 3800
            : 2400
          : initialScreenSize >= 600
          ? currentScreenSize >= 600
            ? 3800
            : 2400
          : currentScreenSize >= 600
          ? 3800
          : 2400
      );

      return () => {
        clearAllBodyScrollLocks();
        clearTimeout(bodyScrollTimer);
      };
    } else {
      clearAllBodyScrollLocks();
    }
  }, [
    dispatch,
    initialScreenSize,
    currentScreenSize,
    LandingPageRef,
    splashScreenComplete,
    cartIsActive,
  ]);

  const handleTouchStart = (e) => {
    if (e.touches.length === 2) {
      if (!twoFingerTouch) {
        changeTwoFingerTouch(true);
      }
    }
  };

  const handleTouchMove = () => {
    if (twoFingerTouch) {
      if (!touchScaling) {
        dispatch(ACTION_TOUCH_SCALING_ACTIVE());
      }
    }
  };

  const handleTouchEnd = () => {
    if (twoFingerTouch) {
      changeTwoFingerTouch(false);
      if (touchScaling) {
        dispatch(ACTION_TOUCH_SCALING_RESET());
      }
    }
  };

  return (
    <div
      className="landing_page_container"
      ref={LandingPageRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        background: splashScreenHalfway
          ? "rgb(224, 224, 232)"
          : "rgb(245, 245, 245)",
        transition: "background 2s ease-out",
        zIndex: finalBookingModal ? -1 : "auto",
      }}
      id={name}
    >
      <section className="main_content">
        <div
          className="landing_page_drawing"
          style={{
            zIndex:
              currentScreenSize === ""
                ? initialScreenSize <= 1000 && initialScreenSize >= 600
                  ? scrollValue <= 1
                    ? navbarToggle
                      ? "1"
                      : "500"
                    : "1"
                  : "1"
                : currentScreenSize <= 1000 && currentScreenSize >= 600
                ? scrollValue <= 1
                  ? navbarToggle
                    ? "1"
                    : "500"
                  : "1"
                : "1",
            left: !currentScreenSize
              ? initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "50%"
                : "0%"
              : currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? "50%"
              : "0%",
            height: !currentScreenSize
              ? initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "90%"
                : "42%"
              : currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? "90%"
              : "42%",
            width: !currentScreenSize
              ? initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "50%"
                : "100%"
              : currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? "50%"
              : "100%",
          }}
        >
          <Spring
            from={{
              opacity: 0,
            }}
            to={{
              opacity: 1,
            }}
            config={{
              delay: !currentScreenSize
                ? initialScreenSize >= 550
                  ? 3000
                  : 2350
                : initialScreenSize >= 550
                ? currentScreenSize >= 550
                  ? 3000
                  : 2350
                : 2350,
              duration: 500,
            }}
          >
            {(propstyles) => (
              <svg
                height={
                  !currentScreenSize
                    ? initialScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                      ? "65%"
                      : "100%"
                    : currentScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                    ? "65%"
                    : "100%"
                }
                width={
                  !currentScreenSize
                    ? initialScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                      ? "100%"
                      : "65%"
                    : currentScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                    ? "100%"
                    : "65%"
                }
                style={{
                  opacity: splashScreenComplete ? 1 : `${propstyles.opacity}`,
                }}
                viewBox="0 0 56.356 56.356"
              >
                {/* Bottom Shelf */}
                <path
                  d="M6.272 44.364c.034-.358.085-.615.226-.723a.653.653 0 01.364-.177c.186-.028 21.072-.018 21.072-.018s21.202.02 21.21-.012c.138.06.221.145.251.207.078.16.168.365.201.723l.008.537c-.041.004-21.67-.009-21.67-.009s-21.602.006-21.637 0z"
                  fill="rgb(94,94,102)"
                />
                <path
                  d="M6.75 46.128c-.292-.115-.453-.415-.453-.842v-.394h43.309v.408c0 .411-.088.807-.572.848l-20.97.036c-11.792.009-21.213-.016-21.314-.056z"
                  fill="rgb(74,74,82)"
                />
                <g fill="rgb(54,54,62)">
                  <path d="M43.406 52.884c.147-.138 4.054-6.582 4.054-6.686 0-.024-.389-.044-.864-.044h-.864l-2.025 3.354-.012-3.354h-1.683l.03 3.258c.03 3.062.044 3.27.231 3.442.253.232.9.25 1.133.03z" />
                  <path d="M43.406 52.884c.147-.138 4.062-6.623 4.062-6.727 0-.025-.397-.003-.872-.003h-.864l-2.025 3.354-.012-3.354h-1.673v3.08c0 3.174.042 3.548.422 3.718.3.133.784.1.962-.068z" />
                </g>
                <g fill="rgb(54,54,62)">
                  <path d="M12.517 52.836c-.152-.137-4.195-6.534-4.195-6.638 0-.024.402-.043.894-.043h.894l1.048 1.665 1.048 1.665.013-3.33h1.741l-.032 3.234c-.03 3.04-.044 3.246-.238 3.417-.261.23-.932.248-1.173.03z" />
                  <path d="M12.517 52.836c-.152-.137-4.212-6.584-4.212-6.687 0-.024.42.006.911.006h.894l1.048 1.665 1.048 1.665.013-3.33h1.731v3.057c0 3.15-.044 3.523-.437 3.69-.31.134-.812.1-.996-.066z" />
                </g>
                {/* Top Shelf */}
                <path
                  d="M6.282 18.964c.033-.358.085-.615.226-.723a.653.653 0 01.363-.176c.186-.028 21.073-.018 21.073-.018s21.201.02 21.21-.013c.138.06.22.146.25.207.078.16.168.365.202.723l.008.537c-.041.004-21.67-.008-21.67-.008s-21.603.006-21.638 0z"
                  fill="rgb(94,94,102)"
                />
                <path
                  d="M6.76 20.729c-.292-.115-.454-.415-.454-.843v-.393h43.31v.407c0 .412-.088.807-.572.848l-20.97.037c-11.792.008-21.213-.016-21.314-.056z"
                  fill="rgb(74,74,82)"
                />
                <g fill="rgb(54,54,62)">
                  <path d="M43.416 27.485c.147-.138 4.054-6.583 4.054-6.687 0-.024-.39-.044-.865-.044h-.864l-2.025 3.355-.012-3.355h-1.682l.03 3.259c.03 3.061.043 3.269.23 3.441.253.232.9.25 1.134.03z" />
                  <path d="M43.416 27.485c.147-.138 4.062-6.624 4.062-6.728 0-.024-.397-.003-.873-.003h-.864l-2.025 3.355-.012-3.355h-1.673v3.08c0 3.174.043 3.549.423 3.718.3.134.784.1.962-.067z" />
                </g>
                <g fill="rgb(54,54,62)">
                  <path d="M12.527 27.437c-.152-.137-4.196-6.535-4.196-6.638 0-.024.403-.044.895-.044h.894l1.048 1.665 1.048 1.665.012-3.33h1.742l-.032 3.234c-.03 3.04-.045 3.246-.239 3.417-.26.23-.931.248-1.172.03z" />
                  <path d="M12.527 27.437c-.152-.137-4.212-6.585-4.212-6.688 0-.024.419.006.91.006h.895l1.048 1.665 1.048 1.665.012-3.33h1.732v3.058c0 3.15-.044 3.522-.437 3.69-.311.133-.812.1-.996-.066z" />
                </g>
                {/* Potted Plant */}
                <g strokeWidth=".32">
                  <path
                    d="M9.949 18.562c-.057-.119-.186-1.321-.288-2.671-.235-3.111-.244-3.164-.587-3.237-.25-.053-.27-.102-.27-.72v-.664h.677c.372 0 .71-.045.75-.102.042-.056-.05-.261-.202-.457-.526-.674-.626-.842-.933-1.56-.381-.892-.498-2.102-.292-3.025.08-.356.209-.668.289-.694.146-.048.943.27 1.618.646.283.157.38.175.414.075.025-.07.137-.432.248-.803.24-.8.898-1.916 1.49-2.528l.426-.44.487.469c.595.572 1.198 1.587 1.506 2.537.128.391.253.73.28.752s.392-.16.814-.403c.422-.244.85-.431.95-.415.343.055.605 1.23.544 2.435-.06 1.178-.266 1.796-.934 2.806l-.403.611h.34c.186 0 .47.042.63.094.27.087.293.14.293.676 0 .525-.028.592-.28.69-.358.138-.343.039-.708 4.797-.038.503-.112 1.012-.164 1.131l-.095.217h-6.497z"
                    fill="rgb(245,148,26)"
                  />
                  <path
                    d="M13.243 15.992V13.2l-3.698-.053-.072-.625h3.77v-1.033l-.528-.061c-.29-.034-.902-.061-1.36-.061h-.831l-.384-.506c-.678-.89-.73-.977-1.044-1.71-.381-.892-.498-2.102-.292-3.025.08-.356.209-.668.289-.694.146-.048.943.27 1.618.646.283.157.38.175.414.075.025-.07.137-.432.248-.803.24-.8.898-1.916 1.49-2.528l.426-.44.487.469c.595.572 1.198 1.587 1.506 2.537.128.391.253.73.28.752s.392-.16.814-.403c.422-.244.85-.431.95-.415.343.055.605 1.23.544 2.435-.06 1.175-.266 1.796-.928 2.797-.22.332-.399.648-.399.703 0 .054.244.112.541.128l.54.03.035.5c.028.415-.004.516-.19.6-.124.056-.278.243-.342.415-.099.263-.303 2.302-.519 5.175l-.046.626-1.66.026-1.659.027z"
                    fill="rgb(224, 135, 16)"
                  />
                  <path
                    d="M13.186 13.1c-.125-.046-.983-.086-1.906-.09-1.377-.006-1.678-.032-1.678-.145 0-.143.088-.147 5.606-.302 1.93-.054 2.08-.018 1.796.433-.12.19-.218.2-1.859.193-.952-.004-1.834-.044-1.959-.09zm-1.669-1.83c-.605-.026-1.119-.07-1.142-.096a40.68 40.68 0 01-.797-1.06c-.405-.564-.792-1.7-.855-2.512-.07-.91.131-2.092.37-2.17.146-.048.943.27 1.618.646.283.157.38.175.414.075.025-.07.137-.432.248-.803.24-.8.898-1.916 1.49-2.528l.426-.44.487.469c.595.572 1.198 1.587 1.506 2.537.128.391.253.73.28.752s.392-.16.814-.403c.422-.244.85-.431.95-.415.124.02.253.253.391.703.275.896.208 2.53-.139 3.359-.261.625-.94 1.7-1.178 1.867-.155.109-2.618.118-4.883.02z"
                    fill="rgb(200, 115, 0)"
                  />
                  <path
                    d="M10.512 11.204c-.162-.033-.25-.136-.874-1.022-.484-.686-.847-1.712-.915-2.58-.07-.91.131-2.092.37-2.17.146-.048.943.27 1.618.646.283.157.38.175.414.075.025-.07.137-.432.248-.803.24-.8.898-1.916 1.49-2.528l.426-.44.487.469c.595.572 1.198 1.587 1.506 2.537.128.391.253.73.28.752s.392-.16.814-.403c.422-.244.85-.431.95-.415.344.055.605 1.23.543 2.435-.062 1.2-.284 1.859-.961 2.843l-.428.622-2.899.01c-1.594.004-2.975-.008-3.07-.028z"
                    fill="rgb(60,105,50)"
                  />
                  <path
                    d="M14.769 11.136c-.06-.129 2.269-5.311 2.465-5.489.135-.121.31.304.459 1.118.226 1.232-.204 2.808-1.084 3.97l-.404.535h-.688c-.482 0-.706-.04-.749-.134zm-2.788-.557c-.682-1.142-2.72-4.813-2.72-4.898 0-.268 1.965.798 2.603 1.412.255.245.657.733.893 1.084l.43.639.03-2.981c.016-1.64.076-3.02.132-3.067.189-.16 1.165 1.147 1.563 2.094.09.216.232.662.315.992.126.506.125.617-.008.71-.333.234-1.193 1.441-1.477 2.073-.204.456-.319.942-.369 1.574l-.073.915-.479.03-.479.029z"
                    fill="rgb(32,62,25)"
                  />
                  <path
                    d="M11.043 8.517c-.795-1.413-1.42-2.592-1.387-2.62.121-.102 1.692.912 2.134 1.377.253.265.627.777.833 1.137.205.36.423.706.483.77.066.069.081.47.039.982-.07.84-.081.868-.364.896-.277.027-.366-.103-1.738-2.542zm3.907 2.506c0-.153 2.325-5.047 2.398-5.047.124 0 .256.912.242 1.681-.019 1.078-.372 2.055-1.087 3.014-.297.397-.318.407-.928.407-.344 0-.625-.025-.625-.055zm-1.48-5.288c0-1.456.04-2.647.086-2.646.255.002 1.183 1.529 1.441 2.373.248.81.239.862-.25 1.333-.239.228-.584.676-.77.996s-.374.583-.42.586-.086-1.186-.086-2.642z"
                    fill="rgb(26,56,19)"
                  />
                </g>
                {/* Tiny Plant */}
                <g strokeWidth=".316">
                  <path
                    d="M21.959 18.612c-.296-.166-.504-.593-.488-1.003.006-.152-.016-.33-.048-.397s-.07-.315-.086-.551a3.834 3.834 0 00-.07-.568 3.253 3.253 0 01-.074-.454c-.027-.271-.05-.325-.164-.386-.109-.058-.135-.113-.148-.309-.025-.391-.016-.395 1.01-.395h2.083c.619 0 .897.02.939.066.033.037.06.182.06.322 0 .22-.018.265-.124.319-.068.034-.124.085-.124.112 0 .027-.031.257-.07.511a13.362 15.177 0 00-.105.835c-.019.205-.054.387-.078.403-.024.016-.043.19-.043.387 0 .402-.09.693-.287.923-.23.27-.394.313-1.214.31-.675 0-.768-.013-.97-.125z"
                    fill="rgb(230, 200, 112)"
                  />
                  <path
                    d="M22.07 18.648c-.322-.176-.555-.604-.556-1.018v-.146l1.379-.04c.758-.022 1.403-.024 1.433-.003.128.088.02.653-.177.913-.248.33-.426.386-1.213.384-.547 0-.742-.021-.866-.09zm-.036-1.782c-.188-.21-.332-.394-.32-.407.013-.012.34-.033.727-.046.648-.022.704-.017.704.073 0 .054-.032.118-.07.142-.038.024-.194.174-.347.333s-.295.29-.315.29-.19-.173-.379-.385zm.863.288c0-.129.485-.73.589-.73.093 0 .696.657.696.76 0 .05-.17.067-.642.067-.58 0-.643-.01-.643-.097zm-1.452-.046c-.041-.12-.037-.519.006-.519.062 0 .459.462.459.535 0 .11-.426.096-.465-.016zm2.673-.286c-.128-.146-.233-.308-.233-.361 0-.085.042-.095.31-.081l.308.016-.016.276c-.024.432-.094.46-.369.15zm-1.96-.66c-.421-.009-.785-.04-.81-.067-.024-.027-.07-.219-.1-.425-.048-.327-.072-.385-.19-.448-.113-.06-.137-.108-.137-.279 0-.113.027-.236.06-.272.041-.047.326-.067.963-.067h.904l.222-.027 1.878.055.015.257c.013.236.002.264-.124.332-.123.065-.147.123-.214.514l-.075.44-.814.003a75.591 75.591 0 01-1.578-.015z"
                    fill="rgb(227, 181, 83)"
                  />
                  <path
                    d="M22.208 18.701a.598.68 0 01-.123-.08 8.236 9.354 0 00-.194-.167c-.167-.14-.325-.498-.326-.741l-.001-.173 1.33-.04c.731-.022 1.354-.024 1.383-.004.138.095 0 .657-.218.883-.331.344-.295.332-1.061.344-.395.006-.75-.003-.79-.022zm-.124-1.834c-.16-.182-.282-.342-.27-.356.033-.035 1.181-.052 1.212-.018.023.026-.542.659-.615.69-.02.007-.166-.135-.327-.316zm.886.27c-.018-.032.093-.198.246-.368l.278-.31.294.325c.162.179.295.345.295.369 0 .067-1.076.052-1.113-.016zm-1.486-.075a.657.657 0 01-.01-.223l.016-.146.39.447h-.184c-.114 0-.195-.03-.212-.078zm2.683-.296c-.1-.115-.183-.239-.183-.276 0-.08.424-.093.468-.014.036.064-.024.5-.069.5-.018 0-.115-.095-.216-.21zm-1.998-.659c-.902-.019-.806.033-.877-.483-.048-.354-.076-.417-.205-.471-.104-.045-.12-.082-.107-.259l.016-.207 1.877-.055.011-.949c.008-.618-.007-.96-.04-.984-.03-.02-.157-.04-.284-.046-.389-.016-.6-.247-.673-.732-.03-.205-.021-.238.08-.3.152-.093.602-.092.702 0 .043.04.113.059.155.041.064-.027.07-.113.044-.544-.03-.495-.027-.515.092-.638.098-.101.169-.124.34-.11.213.016.215.019.23.24.017.27-.119.495-.333.548-.079.019-.157.074-.173.122-.049.141-.023 1.501.028 1.537.026.018.108-.012.181-.067.158-.117.618-.134.788-.03.142.088.148.382.016.708-.127.311-.3.426-.693.46l-.323.027v.717h.953c.931 0 .954.003.98.117.053.239.001.425-.122.435-.145.012-.195.1-.233.413a3.51 3.51 0 01-.067.386l-.037.137-.762.003a88.478 100.492 0 01-1.564-.016z"
                    fill="rgb(238, 150, 31)"
                  />
                  <path
                    d="M22.958 18.147l.013-.593.615-.015c.338-.01.642-.005.674.009.082.035-.001.515-.12.695-.05.076-.164.194-.254.262s-.143.126-.12.128c.026.002 0 .025-.053.051-.055.026-.25.05-.434.051l-.335.004zm.038-1.034c0-.049.36-.473.445-.524.064-.038.142.018.348.251l.266.3h-.53c-.29 0-.529-.012-.529-.027zm1.194-.377c-.086-.099-.156-.197-.156-.218s.089-.039.197-.039c.193 0 .198.005.198.19 0 .261-.057.277-.24.067zm-1.218-.71a1.719 1.719 0 01-.036-.264l-.014-.193-.815-.027-.815-.028-.031-.356.83-.015.831-.015-.007-.248c-.004-.136.093-.292.162-.236.122.1 1.796.101 1.798.002 0-.038.024-.025.052.028.028.054.044.19.036.303-.014.186-.028.205-.143.19-.119-.016-.134.008-.208.33a8.107 8.107 0 00-.095.458c-.014.104-.058.111-.769.126-.577.011-.759-.002-.776-.055z"
                    fill="rgb(221, 136, 18)"
                  />
                  <path
                    d="M22.169 15.5c-.512-.01-.852-.038-.852-.07v-.095c-.001-.08 3.203-.21 3.273-.132.027.03.035.119.017.197l-.032.142-.777-.013-1.63-.029zm.723-1.295c-.003-.22-.001-.64.004-.931.007-.357-.008-.544-.047-.571-.032-.022-.162-.044-.289-.05-.389-.016-.6-.247-.673-.732-.03-.205-.021-.238.08-.3.152-.093.602-.092.702 0 .043.04.113.059.155.041.064-.027.07-.113.044-.544-.03-.495-.027-.515.092-.638.098-.101.169-.124.34-.11.213.016.215.019.23.24.017.27-.119.495-.333.548-.079.019-.157.074-.173.122-.049.141-.023 1.501.028 1.537.026.018.108-.012.181-.067.158-.117.618-.134.788-.03.142.088.148.382.016.708-.127.311-.3.426-.693.46l-.323.027-.015.345c-.02.476-.107.434-.114-.055z"
                    fill="rgb(200,115,0)"
                  />
                  <path
                    d="M22.892 14.205c-.003-.22-.001-.64.004-.931.007-.357-.008-.544-.047-.571-.032-.022-.162-.044-.289-.05-.389-.016-.6-.247-.673-.732-.03-.205-.021-.238.08-.3.152-.093.602-.092.702 0 .043.04.113.059.155.041.064-.027.07-.113.044-.544-.03-.495-.027-.515.092-.638.098-.101.169-.124.34-.11.213.016.215.019.23.24.017.27-.119.495-.333.548-.079.019-.157.074-.173.122-.049.141-.023 1.501.028 1.537.026.018.108-.012.181-.067.158-.117.618-.134.788-.03.142.088.148.382.016.708-.127.311-.3.426-.693.46l-.323.027-.015.345c-.02.476-.107.434-.114-.055z"
                    fill="rgb(89,131,80)"
                  />
                  <path
                    d="M23.03 13.785c-.087-.038-.087-.042 0-.13.283-.285.984-.802 1.026-.756.062.068-.05.54-.161.676-.185.229-.596.329-.865.21zm-.813-1.307a.791.791 0 01-.184-.245c-.073-.156-.103-.496-.045-.496.046 0 .836.646.907.742.057.076.028.085-.252.085-.207 0-.355-.03-.426-.086zm.73-1.39c0-.072.44-.619.499-.619.088 0 .042.328-.067.466-.113.144-.432.257-.432.154z"
                    fill="rgb(60,90,53)"
                  />
                </g>
                {/* Tub of Cream */}
                <g strokeWidth=".265">
                  <path
                    d="M29.793 18.627c-.282-.08-.596-.412-.673-.712-.053-.206-.059-2.815-.007-2.934.034-.076.137-.12.327-.141l.087-.01.016-.254c.017-.27.077-.435.205-.558.087-.085.306-.187.401-.188.066 0 .079-.019.108-.15.132-.593.667-1.124 1.231-1.222.095-.016.55-.038 1.01-.048.46-.01.892-.026.961-.036.257-.035.468-.291.51-.616.026-.201.102-.29.248-.29.088 0 .13.021.211.108.24.256.397.609.464 1.042.04.256.04.312 0 .548a2.907 2.907 0 01-.114.448l-.07.185h.141c.188 0 .429.097.538.217.142.155.19.284.208.56l.017.255.157.02c.279.035.263-.064.255 1.609-.008 1.414-.01 1.453-.07 1.59a1.327 1.327 0 01-.403.475c-.23.147-.223.146-3.01.144-2.063-.002-2.64-.01-2.748-.042z"
                    fill="rgb(235, 235, 235)"
                  />
                  <path
                    d="M29.787 18.605c-.138-.05-.214-.105-.377-.275-.17-.176-.215-.244-.267-.404-.06-.186-.063-.238-.063-1.542v-1.35l.065-.06c.036-.033.091-.072.123-.086s.785-.033 1.673-.042l1.615-.016.008-.486.008-.485h1.195c1.125 0 1.204.003 1.35.06.27.104.397.304.418.657a.879.879 0 00.042.262c.016.02.092.037.17.037.113 0 .158.015.214.073l.07.074v1.37c0 1.198-.006 1.39-.047 1.52-.101.31-.355.58-.66.698-.158.06-.197.061-2.762.059-2.556-.002-2.605-.003-2.775-.064zm2.769-1.817v-.852l-.86-.008c-.54-.005-.904.004-.974.024-.14.039-.3.18-.374.331-.047.096-.056.177-.056.51 0 .34.008.413.06.524.06.127.237.284.358.316.032.009.46.014.952.011l.894-.004zM30.249 13.8c-.01-.016-.003-.087.016-.157.152-.584.673-1.09 1.224-1.185.095-.017.55-.038 1.01-.048.46-.01.892-.027.96-.036.257-.036.469-.292.51-.616.027-.202.103-.29.25-.29.086 0 .128.02.21.108.24.256.397.608.464 1.041.04.256.04.313 0 .547-.043.256-.135.553-.194.627-.038.048-4.422.056-4.45.009z"
                    fill="rgb(215, 215, 215)"
                  />
                  <path
                    d="M29.801 18.59a1.258 1.258 0 01-.644-.68l-.077-.21v-2.64l.168-.146 3.042-.022c1.673-.012 3.167-.012 3.32 0 .21.016.296.035.35.08l.071.058v1.35c0 1.29-.002 1.358-.059 1.512a1.199 1.199 0 01-.69.718c-.157.062-.193.062-2.726.06l-2.567-.003zm4.716-.953c.252-.14.339-.313.355-.706.024-.576-.056-.799-.347-.966l-.152-.088-1.742-.008c-2.006-.01-1.966-.014-2.199.228-.167.174-.211.356-.192.799.015.351.082.534.244.661.194.154.231.156 2.144.15 1.755-.006 1.775-.007 1.889-.07zM30.25 13.8c-.01-.016-.003-.087.015-.157.152-.584.674-1.09 1.225-1.185.095-.017.549-.038 1.009-.048.46-.01.893-.027.962-.036.256-.036.468-.292.51-.616.025-.202.101-.29.248-.29.087 0 .129.02.21.108.24.256.397.608.465 1.041.04.256.04.313 0 .547-.043.256-.135.553-.195.627-.037.048-4.421.056-4.45.009z"
                    fill="#f9d764"
                  />
                  <path
                    d="M29.801 18.59a1.258 1.258 0 01-.644-.68l-.077-.21v-2.64l.168-.146 3.042-.022c1.673-.012 3.167-.012 3.32 0 .21.016.296.035.35.08l.071.058v1.35c0 1.29-.002 1.358-.059 1.512a1.199 1.199 0 01-.69.718c-.157.062-.193.062-2.726.06l-2.567-.003zm4.716-.953c.252-.14.339-.313.355-.706.024-.576-.056-.799-.347-.966l-.152-.088-1.742-.008c-2.006-.01-1.966-.014-2.199.228-.167.174-.211.356-.192.799.015.351.082.534.244.661.194.154.231.156 2.144.15 1.755-.006 1.775-.007 1.889-.07zm-1.94-4.517l.008-.65.476-.016c.536-.017.638-.05.801-.256.082-.103.18-.38.18-.51 0-.081.105-.22.167-.22.153 0 .546.663.61 1.03.067.391.024.819-.114 1.143l-.055.129h-2.08z"
                    fill="#f7bd69"
                  />
                  <path
                    d="M29.801 18.59a1.258 1.258 0 01-.644-.68l-.077-.21v-2.64l.168-.146 3.042-.022c1.673-.012 3.167-.012 3.32 0 .21.016.296.035.35.08l.071.058v1.35c0 1.29-.002 1.358-.059 1.512a1.199 1.199 0 01-.69.718c-.157.062-.193.062-2.726.06l-2.567-.003zm4.716-.953c.252-.14.339-.313.355-.706.024-.576-.056-.799-.347-.966l-.152-.088-1.742-.008c-2.006-.01-1.966-.014-2.199.228-.167.174-.211.356-.192.799.015.351.082.534.244.661.194.154.231.156 2.144.15 1.755-.006 1.775-.007 1.889-.07z"
                    fill="rgb(0, 129, 177)"
                  />
                  <path
                    d="M29.911 18.583a1.7 1.7 0 01-.307-.147 1.273 1.273 0 01-.407-.547 3 3 0 00-.09-.24c-.015-.03-.027-.594-.027-1.288v-1.235l.177-.176h6.625l.075.073.074.072v2.541l-.076.197c-.13.337-.315.563-.557.677l-.22.105c-.112.055-.217.057-2.58.054l-2.465-.002zm4.507-.856c.209-.063.435-.295.484-.497.047-.196.042-.699-.01-.877a.74.74 0 00-.362-.447l-.14-.074-1.812.001c-1.437.001-1.833.01-1.919.042a.675.675 0 00-.387.368c-.077.163-.081.193-.08.56.001.347.008.404.068.524.12.242.278.365.532.417.066.014.873.026 1.795.028 1.454.003 1.695-.003 1.831-.045z"
                    fill="rgb(0, 129, 166)"
                  />
                  <path
                    d="M32.577 18.23l.008-.44.91-.015c.905-.015.91-.016 1.052-.092a.78.78 0 00.361-.454c.052-.2.024-.848-.043-.99a.767.767 0 00-.41-.377c-.144-.056-.224-.06-1.021-.06h-.865l.008-.426.008-.426 1.384-.01c1.632-.01 1.884.002 1.989.096l.073.065v1.263c0 .782-.01 1.282-.028 1.314a2.263 2.263 0 00-.09.231c-.048.14-.103.222-.253.376a.98.98 0 01-.432.291l-.24.096h-2.42z"
                    fill="rgb(0, 105, 141)"
                  />
                </g>
                {/* Hand cream container */}
                <g>
                  <g strokeWidth=".265">
                    <path
                      d="M40.642 16.227c.002-2.091-.009-2.469-.111-3.89a890.01 890.01 0 01-.413-6.106c0-.075 6.494-.082 6.518-.008.008.026 0 .25-.017.499-.017.248-.052.76-.076 1.138-.025.378-.064.936-.087 1.241a143.039 148.067 0 00-.085 1.22 463.88 463.88 0 01-.199 2.925c-.05.7-.068 1.562-.068 3.059.001 1.146-.008 2.118-.019 2.161-.019.073-.186.078-2.733.078H40.64z"
                      fill="rgb(28, 157, 205)"
                    />
                    <path
                      d="M40.713 18.533c-.002-.007-.011-.767-.02-1.69-.01-1.07 0-1.71.028-1.763.04-.078.114-.083 1.3-.083 1.107 0 1.26-.008 1.283-.07.015-.038.026-2.023.026-4.411v-4.34h1.628c.895 0 1.627.016 1.627.036 0 .035-.167 2.577-.256 3.887-.025.365-.063.904-.086 1.196-.022.293-.062.862-.088 1.264l-.101 1.551c-.03.457-.038.878-.019.951.039.145.046 3.316.008 3.418-.02.055-.397.065-2.674.065-1.458 0-2.653-.005-2.656-.011z"
                      fill="#d3d4d6"
                    />
                    <path
                      d="M40.7 18.474c-.043-.116-.029-.728.019-.82.043-.083.118-.086 2.672-.086 2.356 0 2.63.008 2.653.07.034.092.034.744 0 .835-.023.063-.298.07-2.672.07-2.373 0-2.648-.007-2.672-.07zm.042-1.685c-.056-.058-.068-.199-.068-.845 0-.45.02-.811.045-.861.042-.082.102-.086 1.301-.086 1.107 0 1.26-.008 1.284-.07.014-.038.026-2.023.026-4.411v-4.34h1.627c.895 0 1.627.016 1.627.036 0 .035-.167 2.577-.256 3.887-.024.365-.063.904-.085 1.196-.023.293-.062.862-.088 1.264l-.102 1.551c-.03.467-.038.878-.018.953.051.193.044 1.608-.009 1.71-.041.08-.096.086-.764.086-.643 0-.728-.009-.791-.081-.039-.045-.07-.101-.07-.127s-.068-.136-.15-.246c-.21-.285-.486-.434-.859-.464-.264-.021-.327-.01-.515.088-.24.126-.528.426-.612.637-.03.077-.098.152-.149.166-.05.014-.366.026-.7.026-.495.001-.62-.012-.674-.069zm2.465.023c-.084-.035-.092-.054-.048-.109.058-.072.286-.087.38-.025a.092.095 0 01.034.11c-.03.079-.205.09-.366.024z"
                      fill="rgb(47, 128, 183)"
                    />
                    <path
                      d="M40.718 18.124c0-.37.01-.425.084-.466.056-.03.927-.042 2.644-.035l2.561.012.012.454.013.455h-5.314zm3.828-1.343a.624.624 0 01-.118-.176 1.28 1.28 0 00-1.238-.728c-.323.012-.766.344-.931.697l-.092.196h-.699c-.597 0-.702-.01-.724-.07-.034-.091-.034-1.453 0-1.544.023-.062.173-.07 1.245-.07.758 0 1.25-.017 1.302-.046.08-.045.082-.141.082-4.455v-4.41h3.17l-.02.233c-.013.128-.042.542-.066.92a281.038 290.917 0 01-.085 1.285c-.023.33-.062.918-.087 1.308a103.862 107.513 0 01-.086 1.242c-.09 1.174-.22 3.297-.217 3.568.002.159.014.664.027 1.124.017.603.01.853-.027.897-.039.049-.196.063-.719.062-.368 0-.69-.015-.717-.033zm-1.237-.055c.015-.024.053-.044.086-.044.032 0 .071.02.085.044.016.025-.021.044-.085.044s-.1-.019-.086-.044z"
                      fill="rgb(0, 129, 177)"
                    />
                    <path
                      d="M40.77 18.111l.012-.432 2.535-.039c1.395-.021 2.565-.027 2.602-.012.054.021.066.11.066.471v.445h-5.227zm3.776-1.33a.823.823 0 01-.136-.213 1.551 1.551 0 00-.553-.564c-.226-.117-.621-.177-.814-.124a1.41 1.41 0 00-.73.558c-.076.122-.16.247-.184.278-.035.042-.21.054-.696.044l-.65-.012-.013-.809a14.433 14.433 0 010-.818c.045-.035 4.997-.134 5.12-.102l.097.025-.011.874-.012.875-.685.01c-.377.007-.707-.003-.733-.022zm-1.194-.056c.015-.024.045-.033.067-.02.06.039.048.065-.027.065-.037 0-.055-.02-.04-.045z"
                      fill="rgb(0, 96, 144)"
                    />
                    <path
                      d="M43.339 18.111l.012-.432 1.282-.012c.997-.009 1.29.001 1.316.045.019.03.035.23.035.444v.388h-2.658zm1.135-1.538c-.167-.362-.5-.623-.941-.737l-.204-.052v-.325c0-.277.013-.333.083-.372.106-.058 2.397-.06 2.487-.002.051.033.067.174.078.694.008.36.002.72-.012.8l-.027.147h-1.394z"
                      fill="rgb(0, 59, 110)"
                    />
                    <path
                      d="M43.382 18.134l.013-.41L45.9 17.7v.352c0 .193.012.383.026.421.023.063-.105.07-1.265.07h-1.29zm1.16-1.53a1.522 1.522 0 00-.44-.57 2.313 2.313 0 00-.437-.21l-.27-.095v-.577l2.505-.023v1.553h-.665c-.595 0-.668-.009-.693-.078z"
                      fill="rgb(0, 38, 87)"
                    />
                  </g>
                </g>
                {/* Mirror */}
                <g>
                  <g strokeWidth=".265">
                    <path
                      d="M11.322 44.121c-.18-.185-.189-.572-.027-1.108.194-.644.775-1.283 1.377-1.514.141-.054.39-.142.43-.173.078-.062.225-1.155.15-1.272-.023-.036-.278-.155-.499-.194-1.78-.312-3.349-1.287-4.255-2.84-.553-.948-.771-1.815-.818-2.826-.029-.617-.104-.532.048-.678.146-.14 1.054.616 1.425-.03l.16-.277.07-.357c.244-1.253 1.137-2.465 2.228-3.025.688-.354 1.236-.462 2.175-.43.894.032 1.36.158 1.976.537.615.378 1.325 1.175 1.66 1.864.068.137.195.51.284.83l.16.58.79.03c.79.03.567.15.713.326.14.17.166.12.133.646-.182 2.864-2.107 5.12-4.844 5.661-.19.038-.52.073-.592.087-.124.025-.108.192-.108.789 0 .415.168.615.21.615.181 0 .805.385 1.074.663.366.377.556.74.677 1.295.1.463.077.65-.106.827-.12.116-.194.12-2.238.12h-2.113zm3.625-5.204c1.016-.189 1.59-.63 2.271-1.302.54-.534.97-1.112 1.23-1.772.2-.506.483-1.524.426-1.618-.017-.03-.482-.134-.67-.061l-.364.141-.093.43a4.462 4.462 0 01-1.043 2c-.638.702-1.145 1.026-2.055 1.315-.441.14-1.514.15-2.008.02-1.664-.44-2.832-1.633-3.226-3.29l-.13-.534-.408-.099-.448.1-.06.205c.001.31.3 1.21.524 1.687.41.875 1.11 1.796 1.896 2.287.417.26.09.481 1.711.85.485.112 1.74-.19 2.277-.35zm-1.37-3.971c.088-.09.093-.915.007-.97-.034-.022-.15.049-.258.157-.256.256-.274.574-.045.761.176.143.204.148.296.052zm-.633-1.841c.3-.302.58-.634.623-.74.097-.234.104-.856.01-.953a.249.249 0 00-.152-.071c-.105 0-1.48 1.395-1.59 1.615-.105.206-.054.427.134.58.265.215.388.16.975-.431z"
                      fill="#d0eafc"
                    />
                    <path
                      d="M11.322 44.121c-.112-.115-.141-.206-.141-.438 0-.933.655-1.856 1.577-2.22l.343-.137.017-.596c.011-.431-.004-.612-.058-.655-.04-.033-.305-.106-.59-.163-1.258-.253-2.196-.752-3.105-1.652a6.422 6.422 0 01-1.793-3.54c-.066-.43-.079-1.007-.025-1.152.068-.183.28-.24.99-.27.809-.034.753.004.892-.623.252-1.14 1.157-2.322 2.182-2.848.688-.354 1.236-.462 2.175-.43.894.032 1.36.158 1.976.537.615.378 1.325 1.175 1.66 1.864.068.137.195.51.283.83l.162.58.716.03c1.053.045 1.13.122 1.06 1.063-.156 2.149-1.52 4.179-3.45 5.137-.53.264-1.462.56-1.762.56-.35 0-.384.064-.384.715 0 .511.014.598.1.629.666.238 1.276.746 1.541 1.282.173.348.334 1.057.286 1.256-.02.08-.092.2-.16.266-.121.117-.195.12-2.239.12h-2.113zm3.455-5.194c1.992-.526 3.542-2.24 3.851-4.26.058-.382.005-.433-.434-.409l-.334.018-.121.492c-.328 1.333-1.289 2.532-2.43 3.034-.657.289-.974.357-1.665.357-1.474.001-2.723-.64-3.524-1.808-.399-.581-.573-.999-.776-1.853l-.06-.252H8.43v.23c0 .621.496 1.847 1.047 2.587.703.945 1.93 1.729 3.11 1.988.353.078 1.718 0 2.19-.124zm-1.201-2.857l.016-.933.156-.085c.086-.046.498-.442.916-.88.65-.682.76-.822.76-.982 0-.215-.25-.533-.43-.548-.27-.024-.356.028-.829.507-.274.278-.515.504-.537.504s-.039-.24-.039-.535v-.535l.232-.26c.307-.345.35-.596.144-.849-.084-.102-.202-.203-.264-.223-.091-.03-.112-.091-.112-.324 0-.533-.063-.56-.823-.36-1.405.372-2.385 1.672-2.387 3.167-.001.895.321 1.708.932 2.347.535.56 1.392.963 1.991.935l.258-.013z"
                      fill="#bae1fd"
                    />
                    <path
                      d="M11.322 44.121c-.112-.115-.141-.206-.141-.438 0-.933.655-1.856 1.577-2.22l.343-.137.017-.596c.011-.431-.004-.612-.058-.655-.04-.033-.305-.106-.59-.163-1.258-.253-2.196-.752-3.105-1.652a6.422 6.422 0 01-1.793-3.54c-.066-.43-.079-1.007-.025-1.152.068-.183.28-.24.99-.27.809-.034.753.004.892-.623.252-1.14 1.157-2.322 2.182-2.848.688-.354 1.236-.462 2.175-.43.894.032 1.36.158 1.976.537.615.378 1.325 1.175 1.66 1.864.068.137.195.51.283.83l.162.58.716.03c1.053.045 1.13.122 1.06 1.063-.156 2.149-1.52 4.179-3.45 5.137-.53.264-1.462.56-1.762.56-.35 0-.384.064-.384.715 0 .511.014.598.1.629.666.238 1.276.746 1.541 1.282.173.348.334 1.057.286 1.256-.02.08-.092.2-.16.266-.121.117-.195.12-2.239.12h-2.113zm3.455-5.194c1.992-.526 3.542-2.24 3.851-4.26.058-.382.005-.433-.434-.409l-.334.018-.121.492c-.328 1.333-1.289 2.532-2.43 3.034-.657.289-.974.357-1.665.357-1.474.001-2.723-.64-3.524-1.808-.399-.581-.573-.999-.776-1.853l-.06-.252H8.43v.23c0 .621.496 1.847 1.047 2.587.703.945 1.93 1.729 3.11 1.988.353.078 1.718 0 2.19-.124zm-.414-1.975c.85-.235 1.6-.843 1.987-1.61.297-.588.392-.973.392-1.586 0-.907-.275-1.615-.888-2.287-.792-.868-1.943-1.204-3.088-.901-1.405.371-2.385 1.67-2.387 3.166-.002 1.505.966 2.818 2.366 3.208.384.107 1.249.112 1.618.01z"
                      fill="rgb(158,162,166)"
                    />
                    <path
                      d="M11.322 44.121c-.111-.114-.141-.207-.141-.434 0-1.109.863-2.12 2-2.343l.348-.069.031-2.078.484-.045c1.533-.144 2.998-1.059 3.898-2.435.417-.637.832-1.832.795-2.288l-.017-.212-.864-.035-.1.492a4.369 4.369 0 01-1.052 2.06c-.638.703-1.145 1.027-2.055 1.316-.441.14-1.514.15-2.008.02-1.688-.447-2.823-1.62-3.242-3.35-.1-.411-.117-.617-.093-1.126.035-.745.14-1.174.444-1.809a4.214 4.358 0 012.746-2.29c.415-.107.6-.12 1.29-.097.685.024.87.052 1.235.186.567.21.972.464 1.42.894.686.657 1.063 1.323 1.3 2.297l.136.552.65.015c.359.008.711.029.784.046.22.053.288.332.234.94-.199 2.22-1.613 4.25-3.56 5.112-.518.229-1.181.423-1.639.478-.207.026-.32.07-.358.144-.03.058-.055.35-.055.646v.54l.215.09c.772.322 1.269.737 1.54 1.286.173.348.334 1.057.286 1.256a.66.66 0 01-.16.266c-.121.117-.195.12-2.239.12h-2.113zm3.09-7.123c1.083-.289 2.007-1.252 2.303-2.399.111-.434.107-1.288-.008-1.707-.25-.91-.873-1.721-1.613-2.107-.994-.517-1.935-.52-2.972-.006-.419.208-.589.334-.875.65-1.236 1.368-1.22 3.376.037 4.683.422.438 1.07.81 1.588.911.455.089 1.157.077 1.54-.025z"
                      fill="rgb(158,162,166)"
                    />
                    <path
                      d="M11.38 44.076c-.139-.17-.152-.225-.125-.519.065-.72.51-1.455 1.1-1.812.297-.18.858-.383 1.057-.383h.149v-2.165l.483-.045c1.534-.144 2.999-1.059 3.899-2.435.417-.637.832-1.832.795-2.288l-.018-.212-.93-.035-.07.373c-.23 1.217-.994 2.342-2.017 2.969-.2.123-.61.305-.91.406-.517.173-.58.18-1.261.155-.616-.023-.796-.055-1.28-.223-1.42-.491-2.39-1.59-2.768-3.13-.166-.68-.172-1.17-.022-1.874.282-1.317 1.141-2.458 2.264-3.006.775-.378 2.037-.58 2.576-.413.097.03.33.1.52.154.726.211 1.485.739 2.02 1.406.331.412.731 1.27.85 1.824.054.245.126.464.161.488.036.024.37.045.742.045.373 0 .725.027.782.059.17.094.217.344.167.913-.193 2.213-1.61 4.25-3.56 5.112-.516.229-1.18.423-1.638.478-.216.027-.32.07-.362.15-.032.064-.05.366-.04.675.015.465.003.56-.068.562-.047.002.057.05.232.109.974.322 1.557.962 1.748 1.92.095.473.078.579-.133.829-.08.095-.257.104-2.137.104h-2.05zm2.783-6.986c.211-.035.58-.163.854-.298 1.206-.593 1.927-1.92 1.812-3.335-.114-1.4-.98-2.511-2.303-2.954-.543-.182-1.447-.184-1.94-.005-1.493.541-2.397 1.897-2.304 3.452.072 1.195.756 2.293 1.75 2.81.7.366 1.32.462 2.13.33z"
                      fill="rgb(158,162,166)"
                    />
                    <path
                      d="M11.38 44.076c-.139-.17-.152-.225-.125-.519a2.418 2.418 0 011.59-2.05c.484-.179.857-.213 1.203-.11.988.294 1.614.964 1.808 1.936.095.474.078.58-.133.83-.08.095-.257.104-2.137.104h-2.05zm1.378-6.063c-1.674-.381-2.852-1.561-3.274-3.28-.166-.68-.172-1.17-.022-1.875.282-1.317 1.141-2.458 2.264-3.006.775-.378 2.037-.58 2.576-.413.097.03.33.1.52.154.726.211 1.485.739 2.02 1.406.52.646.99 1.952.99 2.746 0 .387-.178 1.203-.368 1.685-.319.813-1.01 1.633-1.76 2.094a5.25 5.25 0 01-.897.4c-.454.15-.616.176-1.118.172a5.567 5.567 0 01-.931-.083zm1.405-.923c.211-.035.58-.163.854-.298 1.206-.593 1.927-1.92 1.812-3.335-.114-1.4-.98-2.511-2.303-2.954-.543-.182-1.447-.184-1.94-.005-1.493.541-2.397 1.897-2.304 3.452.072 1.195.756 2.293 1.75 2.81.7.366 1.32.462 2.13.33z"
                      fill="rgb(158,162,166)"
                    />
                    <path
                      d="M11.497 44.09c-.143-.154-.164-.22-.157-.497.013-.484.204-.937.567-1.343.402-.45.855-.686 1.492-.782.413-.062.51-.058.787.032.461.15.992.545 1.24.922.388.59.527 1.342.304 1.65l-.119.165-1.974.016-1.975.015zm1.473-6.114a4.788 4.788 0 01-1.657-.654c-.2-.135-.54-.44-.755-.678-.585-.648-.926-1.396-1.1-2.421-.125-.729.124-1.883.58-2.683.25-.438.964-1.179 1.383-1.434.74-.45 2.354-.851 2.706-.671.066.034.288.103.493.154.205.05.592.203.86.34.398.2.583.345 1.005.784.69.717.988 1.304 1.201 2.361.114.56.109.854-.024 1.474-.336 1.573-1.257 2.694-2.67 3.248-.44.174-.599.206-1.088.22a6.323 6.323 0 01-.934-.04zm1.163-.826c1.187-.175 2.272-1.175 2.65-2.444.145-.486.13-1.514-.03-2.001-.467-1.42-1.587-2.298-3.063-2.402-.43-.03-.614-.016-.964.08-1.183.32-2.125 1.287-2.435 2.5-.11.43-.108 1.286.006 1.74.22.886.9 1.792 1.629 2.175.766.402 1.293.486 2.207.352z"
                      fill="rgb(0, 129, 177)"
                    />
                  </g>
                  <path
                    d="M12.558 32.127c.152-.15.459-.452.623-.607.166-.156.188-.166.213-.17a.214.218 0 01.083 0 .171.174 0 01.073.043.21.21 0 01.061.1.805.805 0 01.024.21c.004.096.004.22-.005.327s-.025.197-.035.245c-.008.048-.012.055-.018.066-.006.012-.067.142-.143.237-.077.094-.193.208-.348.363-.156.156-.35.353-.478.471a1.063 1.08 0 01-.244.18.296.3 0 01-.145.046.343.349 0 01-.16-.056.59.6 0 01-.272-.347.499.507 0 01.017-.22.331.337 0 01.043-.089c.02-.03.048-.063.14-.171l.337-.385c.08-.093.08-.093.233-.243zM13.163 34.35c.011-.017.03-.054.055-.092a.854.868 0 01.204-.22.543.552 0 01.094-.06.166.168 0 01.05-.018c.01 0 .02 0 .026.01a.628.638 0 01.04.149c.007.04.01.088.013.153a4.047 4.114 0 01-.011.45.795.808 0 01-.021.14.257.26 0 01-.028.063c-.006.011-.006.011-.037.038s-.01.01-.028.021c-.016.012-.028.017-.043.016a.16.162 0 01-.05-.013.306.311 0 01-.054-.03.698.71 0 01-.22-.244.46.468 0 01-.033-.176c0-.02 0-.04.007-.066l.02-.084.016-.036z"
                    fill="#fff"
                  />
                </g>
                {/* Bottle */}
                <g strokeWidth=".265">
                  <path
                    d="M21.729 43.974c-.055-.064-.06-.374-.06-3.673V36.7l.115-.244c.14-.302.47-.651.789-.836l.23-.134.01-.19c.017-.343.025-.356.216-.37l.17-.01.01-.45.011-.447-.091-.037c-.091-.037-.091-.037-.091-.533v-.497h.47c.258 0 .538.016.621.036.083.02.321.055.529.08.48.058 1.344.237 1.523.317l.137.06-.01.259-.011.258L24.64 34v.915l.18.02.18.018.018.267.018.266.23.134c.33.19.661.543.795.843l.11.244v3.606c0 3.444-.003 3.608-.066 3.668-.06.058-.264.063-2.191.063-2.013 0-2.129-.003-2.185-.07zm3.397-3.857v-1.753h-2.412v1.728c0 .95.01 1.74.023 1.753.014.014.557.026 1.207.026h1.182z"
                    fill="rgb(168,172,176)"
                  />
                  <path
                    d="M21.729 43.974c-.055-.064-.06-.373-.06-3.656 0-3.435.003-3.594.068-3.752.23-.558.813-1.037 1.463-1.203.29-.074 1.152-.075 1.44 0 .322.082.73.3.965.513.246.224.362.38.48.64l.085.19v3.606c0 3.445-.002 3.608-.065 3.668-.06.058-.264.063-2.191.063-2.014 0-2.129-.003-2.185-.07zm3.425-3.847l.009-1.763h-2.485v1.75c0 1.364.01 1.755.044 1.77.025.01.58.016 1.234.013l1.188-.007zm-1.847-5.28a3.361 3.361 0 01-.01-.466l.01-.382h1.225l.01.414c.007.273-.004.432-.031.467-.033.042-.168.053-.613.053-.562 0-.571-.001-.591-.085z"
                    fill="rgb(186,173,164)"
                  />
                  <path
                    d="M21.748 43.946c-.03-.071-.042-1.037-.042-3.661V36.72l.098-.217c.144-.32.547-.726.896-.9.152-.077.383-.166.514-.198.321-.08 1.095-.079 1.418 0 .598.149 1.159.578 1.386 1.064l.117.25v3.565c0 2.624-.01 3.59-.042 3.662l-.041.097H21.79zm3.394-2.022c.05-.02.057-.216.057-1.811v-1.788l-1.27.01-1.269.01-.01 1.749c-.006 1.26.003 1.764.032 1.801.033.042.268.052 1.222.052.65 0 1.207-.01 1.238-.023zm-1.834-7.076a3.361 3.361 0 01-.01-.467l.01-.382h1.225l.01.414c.007.273-.004.432-.031.467-.033.042-.169.053-.613.053-.562 0-.571-.001-.591-.085z"
                    fill="rgb(181,168,159)"
                  />
                  <path
                    d="M21.783 43.946c-.03-.071-.042-1.037-.042-3.66v-3.563l.112-.237c.223-.474.803-.91 1.383-1.038.143-.032.446-.056.702-.056.664 0 1.06.117 1.5.443.27.2.436.4.564.677l.097.208v3.565c0 2.624-.011 3.59-.042 3.661l-.042.097h-4.19zm3.391-2.009c.057-.043.06-.188.052-1.839l-.01-1.791h-2.592l-.01 1.791c-.008 1.65-.004 1.796.052 1.84.089.068 2.42.068 2.508 0zm-1.867-7.09a3.361 3.361 0 01-.01-.466l.011-.382h1.224l.01.414c.008.273-.003.432-.03.467-.033.042-.169.053-.613.053-.563 0-.572-.001-.592-.085z"
                    fill="rgb(142,148,157)"
                  />
                  <path
                    d="M21.783 43.946c-.03-.071-.042-1.037-.042-3.66v-3.563l.112-.237c.223-.474.803-.91 1.383-1.038.143-.032.446-.056.702-.056.664 0 1.06.117 1.5.443.27.2.436.4.564.677l.097.208v3.565c0 2.624-.011 3.59-.042 3.661l-.042.097h-4.19zm3.391-2.009c.057-.043.06-.188.052-1.839l-.01-1.791h-2.592l-.01 1.791c-.008 1.65-.004 1.796.052 1.84.089.068 2.42.068 2.508 0z"
                    fill="rgb(0, 129, 177)"
                  />
                  <path
                    d="M21.813 43.946c-.022-.061-.035-1.438-.035-3.66v-3.563l.105-.22c.096-.202.312-.459.551-.654.154-.127.559-.308.818-.366.31-.07 1.034-.068 1.352.003.583.131 1.143.556 1.356 1.03l.103.228v3.553c0 2.614-.01 3.578-.042 3.65l-.041.096h-4.132zm3.436-2.021c.033-.091.025-3.573-.008-3.628-.023-.038-.315-.046-1.34-.038l-1.313.01-.01 1.81c-.004.996 0 1.832.009 1.858.013.037.313.048 1.329.048 1.163 0 1.314-.007 1.333-.06z"
                    fill="rgb(0, 129, 177)"
                  />
                  <path
                    d="M21.857 43.917c-.033-.095-.044-1.004-.044-3.652V36.74l.097-.208c.2-.43.686-.815 1.23-.975.281-.083 1-.112 1.317-.053.625.116 1.146.458 1.421.932l.13.225.011 3.548c.009 2.694 0 3.583-.032 3.692l-.044.142H21.9zm3.414-1.932c.054-.09.046-3.67-.008-3.728-.067-.07-2.633-.065-2.69.006-.029.038-.037.566-.03 1.895l.009 1.843.09.027c.05.015.653.025 1.342.02 1.07-.005 1.258-.014 1.287-.063z"
                    fill="rgb(0, 129, 177)"
                  />
                  <path
                    d="M22.658 38.344l2.539-.02-.023 3.612-2.508-.033z"
                    fill="#fff"
                  />
                </g>
                {/* Q-Tips */}
                <g>
                  <g strokeWidth=".265">
                    <path
                      d="M32.32 43.962c-.336-.032-.744-.095-.812-.126l-.058-.026v-1.474c0-1.57.002-1.55-.1-1.526-.09.02-.092.073-.093 1.529 0 1.386 0 1.392-.044 1.392-.062 0-.166-.068-.192-.127-.014-.032-.022-.614-.022-1.69v-1.642l-.064-.06-.064-.061v-1.072l.052-.059c.058-.064.056-.063.291-.137.704-.222 2.54-.264 3.609-.082.197.033.505.125.587.174.143.087.143.087.143.633v.496l-.171.184v1.654c0 1.542-.003 1.656-.038 1.692-.117.123-.386.209-.875.28-.293.043-.518.055-1.139.062a15.61 15.61 0 01-1.01-.014zm3.887-.318c-.22-.114-.257-.453-.093-.831l.084-.194v-2.512l-.086-.209c-.114-.276-.132-.467-.06-.631.069-.158.141-.215.29-.226.152-.012.248.042.333.188.1.17.07.438-.078.741l-.055.113v2.548l.055.113c.085.174.138.37.137.512 0 .156-.08.31-.193.379a.386.386 0 01-.334.008z"
                      fill="rgb(255,255,255)"
                    />
                    <path
                      d="M36.23 42.556c-.006-.02-.01-.565-.01-1.212 0-.912.005-1.182.026-1.197.037-.027.214-.027.247 0 .021.018.027.288.027 1.207 0 .651-.008 1.195-.016 1.21-.026.04-.26.034-.273-.008zm-3.948-2.06c-.675-.05-1.184-.167-1.326-.304l-.075-.072-.006-.508c-.006-.552-.005-.559.116-.62.097-.048.36-.128.537-.162.492-.097 1.497-.145 2.205-.107.822.044 1.078.078 1.434.187.232.072.23.07.3.141l.065.065v.474c0 .261-.01.495-.021.521-.083.184-.597.322-1.45.386-.368.028-1.402.028-1.78 0z"
                      fill="rgb(168,172,176)"
                    />
                    <path
                      d="M36.25 42.52a34.244 34.244 0 01-.004-1.188l.005-1.155h.237v2.36l-.113.009c-.078.005-.117-.003-.124-.026zm-3.907-2.05a5.324 5.324 0 01-.971-.141c-.275-.071-.335-.095-.432-.173l-.07-.056v-.985l.06-.05c.117-.099.448-.195.896-.26.713-.104 2.072-.104 2.728 0 .445.07.8.17.89.25l.055.049.006.49c.006.476.005.491-.041.542-.197.22-1.157.366-2.338.357-.278-.002-.63-.012-.783-.023z"
                      fill="rgb(168,172,176)"
                    />
                    <path
                      d="M36.262 41.357V40.19h.215v2.336h-.215zm-4.038-.912a24.13 28.83 0 00-.398-.051c-.336-.04-.728-.15-.856-.24l-.1-.07v-.965l.102-.065c.466-.298 2.329-.412 3.636-.222.249.036.683.147.762.195.143.086.14.077.14.544 0 .483-.01.525-.142.612-.113.074-.483.17-.814.21l-.429.055c-.206.028-1.704.026-1.901-.003z"
                      fill="rgb(163,167,171)"
                    />
                    <path
                      d="M36.29 41.364l.005-1.136.08-.008.08-.008v2.287h-.172zm-4.088-.948a53.826 64.31 0 00-.376-.05c-.408-.053-.737-.148-.884-.256l-.072-.053v-.912l.113-.067c.59-.354 2.883-.427 4.065-.13.269.068.405.133.428.203.021.067.015.823-.008.894a.263.263 0 01-.102.115c-.106.064-.532.172-.822.208-.124.015-.304.039-.399.053-.209.03-1.716.027-1.943-.005z"
                      fill="rgb(158,162,166)"
                    />
                    <path
                      d="M32.846 39.689c-.066-.084-.23-.092-.29-.013-.052.07-.142.065-.201-.01a.249.249 0 00-.135-.077c-.072-.014-.096-.007-.15.048-.085.085-.113.082-.202-.025-.062-.074-.092-.09-.164-.09-.052 0-.105.017-.128.042-.063.067-.148.052-.21-.039-.042-.06-.092-.092-.2-.128a.848.848 0 01-.22-.109c-.075-.061-.103-.151-.046-.151.016 0 .041-.017.056-.038.049-.07.431-.18.838-.241.41-.061.754-.08 1.404-.08.876 0 1.312.04 1.828.17.397.1.497.167.441.291-.029.065-.104.111-.274.168-.1.033-.147.065-.179.119-.033.055-.061.073-.115.073-.039 0-.083-.017-.098-.038-.015-.023-.062-.039-.111-.039-.066 0-.1.017-.148.075-.09.108-.129.115-.214.04-.062-.055-.089-.063-.16-.05a.254.254 0 00-.135.079c-.062.077-.148.079-.214.005-.07-.078-.212-.069-.28.02-.064.08-.143.08-.202-.002-.037-.05-.068-.064-.143-.064-.074 0-.107.014-.145.064-.028.035-.073.064-.101.064-.028 0-.074-.029-.102-.064z"
                      fill="rgb(153,157,161)"
                    />
                    <path
                      d="M32.89 39.641c-.021-.068-.076-.092-.21-.093-.071 0-.102.014-.138.064-.059.08-.097.08-.167.002a.274.274 0 00-.165-.078c-.099-.016-.116-.011-.17.048a.28.28 0 01-.074.066.37.37 0 01-.08-.09c-.054-.07-.084-.09-.15-.092-.114-.005-.142.002-.18.043-.053.057-.126.045-.148-.023-.02-.063-.075-.098-.237-.147a1.048 1.252 0 01-.18-.08c-.08-.05-.081-.052-.043-.102a.348.348 0 01.104-.084c.082-.04.566-.156.806-.192.724-.108 2.06-.098 2.814.022.293.046.69.171.74.233l.044.053-.05.05a.72.72 0 01-.205.104c-.106.037-.17.076-.208.128-.063.084-.117.094-.174.032-.072-.078-.303-.03-.33.069-.02.08-.078.084-.135.01-.049-.062-.062-.066-.173-.05-.091.012-.132.032-.165.08-.056.08-.11.079-.173-.002-.079-.102-.318-.082-.353.03-.025.077-.08.077-.106-.002-.038-.119-.31-.119-.376 0-.043.078-.093.079-.118.001z"
                      fill="rgb(158,152,156)"
                    />
                  </g>
                </g>
                {/* Sun Screen */}
                <g strokeWidth=".265">
                  <path
                    d="M41.314 41.747a106.27 106.27 0 00-.14-4.222c-.063-1.05-.15-2.578-.196-3.397a205.602 242.537 0 00-.115-1.957l-.03-.469h3.288c2.111 0 3.295.023 3.307.064.01.035.003.319-.016.63-.019.313-.086 1.557-.15 2.767s-.143 2.462-.176 2.783-.072 1.761-.086 3.202c-.014 1.441-.037 2.686-.052 2.766l-.025.146h-5.581z"
                    fill="#f9d59b"
                  />
                  <path
                    d="M41.37 41.682a92.563 92.563 0 00-.141-4.351 1399.275 1650.645 0 01-.2-3.397 145.062 171.122 0 00-.112-1.828l-.029-.404h6.462l-.02.275-.078 1.148c-.057.828-.166 2.817-.364 6.654-.07 1.367-.074 1.752-.02 1.945.043.156.054.396.03.69-.02.247-.04.718-.046 1.047l-.01.6h-5.449zm5.006-5.262c0-.339-.013-.39-.096-.371-.173.038-.159.764.014.764.063 0 .082-.089.082-.393z"
                    fill="#f9d764"
                  />
                  <path
                    d="M41.37 41.682a92.563 92.563 0 00-.141-4.351 1399.275 1650.645 0 01-.2-3.397 145.062 171.122 0 00-.112-1.828l-.029-.404h6.462l-.02.275-.078 1.148c-.057.828-.166 2.817-.364 6.654-.07 1.367-.074 1.752-.02 1.945.043.156.054.396.03.69-.02.247-.04.718-.046 1.047l-.01.6h-5.449zm2.175-3.186l.15-.322-.261-.195c-.144-.107-.28-.195-.304-.195-.067 0-.32.5-.32.633 0 .123.343.398.5.4.048.001.152-.14.236-.32zm1.77.159c.13-.09.238-.18.238-.2 0-.06-.283-.605-.333-.642-.076-.055-.544.294-.544.405 0 .163.233.601.32.601.045 0 .188-.074.32-.164zm-.761-1.036c.444-.22.688-.646.688-1.208 0-.486-.212-.91-.566-1.134-.516-.326-1.19-.095-1.476.504-.135.283-.169.807-.071 1.1.218.656.888 1.002 1.425.738zm1.849-1.194v-.42l-.242-.02a1.3 1.3 0 00-.373.017c-.121.036-.13.066-.114.409l.016.37.247.04c.468.076.466.077.466-.396zm-3.784-.032c0-.386-.001-.389-.168-.408a.713.713 0 00-.315.047c-.133.06-.148.099-.148.38 0 .174.018.336.039.361.022.025.163.037.315.027l.277-.02zm.799-1.531c.116-.077.222-.174.235-.215.028-.085-.269-.68-.338-.68-.024 0-.16.087-.303.193l-.258.194.139.324c.156.364.22.386.525.184zm1.998-.184l.14-.324-.26-.194c-.142-.106-.278-.194-.301-.194-.067 0-.32.5-.319.63 0 .122.347.398.506.403.06.002.146-.116.234-.321z"
                    fill="#f7bd69"
                  />
                  <path
                    d="M41.44 42.329c0-1.462-.134-4.831-.245-6.163-.016-.195-.066-1.069-.11-1.94-.045-.873-.096-1.797-.114-2.055l-.033-.469h5.221l-.029 4.238-.22.032-.219.032-.016.373c-.012.265.004.38.055.396.04.012.145.049.236.081l.164.06.028 2.424.027 2.425.33.032.328.033.016.469.016.469h-.293c-.16 0-.322.035-.358.078-.042.048-.066.29-.066.647v.569H41.44v-1.731zm2.113-3.834l.139-.324-.259-.194c-.142-.106-.279-.193-.304-.193-.065 0-.377.596-.355.677.026.092.428.356.544.357.06 0 .148-.12.235-.323zm1.75.177c.118-.08.224-.174.234-.211.022-.08-.29-.677-.355-.677-.024 0-.162.088-.306.195l-.26.195.149.323c.083.18.187.322.236.32.048 0 .184-.065.302-.145zm-.748-1.053c.439-.217.687-.645.69-1.194.004-.732-.4-1.236-1.027-1.28-.442-.031-.763.16-.986.585-.126.24-.157.372-.157.681 0 .423.084.666.322.933.322.36.77.466 1.158.275zm-1.935-1.226c0-.386-.001-.389-.168-.408a.713.713 0 00-.315.047c-.133.06-.148.099-.148.38 0 .174.018.336.04.361.02.025.163.037.315.027l.276-.02zm.832-1.556c.13-.09.237-.187.237-.213 0-.085-.324-.658-.371-.658-.025 0-.162.088-.305.194l-.258.194.139.324c.082.19.176.324.23.324.05 0 .197-.074.328-.165zm1.964-.13c.169-.34.146-.408-.205-.62-.244-.146-.286-.124-.464.25-.135.282-.12.335.15.527.276.198.355.174.52-.157z"
                    fill="#f6ab2e"
                  />
                  <path
                    d="M41.476 42.49c.008-1.36-.093-4.285-.175-5.062a82.633 82.633 0 01-.108-1.941c-.078-1.609-.17-3.125-.218-3.575l-.023-.21h2.58c2.331 0 2.578.01 2.548.102-.019.056-.034.988-.034 2.07v1.969h-.16c-.248 0-.333.148-.333.579 0 .413.048.498.301.531l.165.022.014 2.356c.01 1.557.035 2.384.073 2.44.034.047.207.091.397.102l.339.02v.776l-.345.032c-.22.021-.365.067-.4.13-.03.053-.048.351-.039.663l.016.566h-4.608zm2.067-3.738c.214-.427.248-.537.2-.643-.064-.14-.59-.475-.682-.433-.11.05-.421.726-.386.836.04.121.511.433.658.435.067 0 .151-.077.21-.195zm1.793.027c.146-.094.28-.213.296-.266.033-.102-.21-.68-.34-.808-.062-.06-.132-.043-.333.082-.14.087-.3.21-.354.274-.095.112-.092.13.076.502.202.446.264.467.655.216zm-.581-1.146c.12-.077.291-.265.382-.418.221-.372.266-1.022.097-1.41-.592-1.359-2.253-.898-2.258.626-.003.843.6 1.473 1.315 1.376.136-.019.345-.097.464-.174zm-2.033-.792a2.29 2.29 0 00.033-.449c0-.524-.146-.638-.61-.474-.177.062-.216.108-.245.29-.055.347.016.657.158.69.312.07.631.043.664-.058zm.751-1.888c.148-.097.281-.217.297-.265.034-.105-.282-.781-.388-.83-.077-.035-.589.268-.687.408-.043.06-.012.186.118.474.206.454.267.474.66.213zm2.026-.19c.208-.435.195-.479-.216-.753-.21-.14-.316-.177-.376-.133-.112.084-.397.723-.366.82.037.113.524.43.664.432.087.002.164-.094.294-.367z"
                    fill="#f5a01a"
                  />
                  <path
                    d="M41.482 42.2c.013-1.592-.018-2.462-.21-6.034-.125-2.295-.249-4.223-.276-4.285s-.05-.127-.05-.146.284-.033.631-.033h.63v4.08l-.149.066c-.203.092-.233.164-.233.577 0 .418.032.493.232.552l.15.044.014 2.371.014 2.37.516.02c.506.018.52.014.686-.171.464-.52.933-.536 1.389-.05.202.215.233.226.73.26.285.02.685.053.889.075l.37.04v.689l-.343.043c-.188.024-1.219.063-2.29.087l-1.947.043-.031 1.262h-.737z"
                    fill="#ef9004"
                  />
                  <path
                    d="M41.499 42.674c-.038-.053-.058-.238-.048-.437l.016-.345.32-.02c.404-.024.476.043.476.446 0 .244-.024.329-.106.381-.157.099-.583.083-.658-.025z"
                    fill="#c87300"
                  />
                </g>
              </svg>
            )}
          </Spring>
        </div>{" "}
        <Spring
          from={{
            top: !currentScreenSize
              ? "100%"
              : // Detect portrait mode or landscape
              initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "0%"
                : "100%"
              : currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? "0%"
              : "0%",
            right: !currentScreenSize
              ? "100%"
              : initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "100%"
                : "0%"
              : currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? "100%"
              : "0%",
          }}
          to={{
            top: !currentScreenSize
              ? initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "0%"
                : "50%"
              : initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "0%"
                : "50%"
              : currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? "0%"
              : "50%",
            right: !currentScreenSize
              ? initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "50%"
                : "0%"
              : initialScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
                ? "50%"
                : "0%"
              : currentScreenSize >= 550 &&
                window.matchMedia("(orientation: landscape)").matches
              ? "50%"
              : "0%",
          }}
          onFrame={(el) => handleSplashScreenHalfway(el)}
          config={{
            delay: !currentScreenSize
              ? initialScreenSize >= 550
                ? 1200
                : 500
              : currentScreenSize >= 550
              ? 1200
              : 500,
            duration: 1800,
          }}
        >
          {(styles) => (
            <div
              className="bottom_content"
              style={{
                top: splashScreenComplete
                  ? !currentScreenSize
                    ? initialScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                      ? "0%"
                      : "50%"
                    : currentScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                    ? "0%"
                    : "50%"
                  : !currentScreenSize
                  ? initialScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                    ? "0%"
                    : `${styles.top}`
                  : initialScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                  ? currentScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                    ? "0%"
                    : `${styles.top}`
                  : currentScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                  ? "0%"
                  : `${styles.top}`,
                right: splashScreenComplete
                  ? !currentScreenSize
                    ? initialScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                      ? "50%"
                      : "0%"
                    : currentScreenSize >= 550 &&
                      window.matchMedia("(orientation: landscape)").matches
                    ? "50%"
                    : "0%"
                  : !currentScreenSize
                  ? initialScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                    ? `${styles.right}`
                    : "0%"
                  : initialScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                  ? currentScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                    ? `${styles.right}`
                    : "0%"
                  : currentScreenSize >= 550 &&
                    window.matchMedia("(orientation: landscape)").matches
                  ? `${styles.right}`
                  : "0%",
              }}
            >
              <Spring
                from={{ opacity: 0 }}
                to={{ opacity: 1 }}
                config={{
                  delay: !currentScreenSize
                    ? initialScreenSize >= 550
                      ? 3000
                      : 2350
                    : initialScreenSize >= 550
                    ? currentScreenSize >= 550
                      ? 3000
                      : 2350
                    : 2350,
                  duration: 500,
                }}
              >
                {(styleopacity) => (
                  <div
                    className="landing_page_text_block"
                    style={{
                      zIndex:
                        currentScreenSize === ""
                          ? initialScreenSize <= 1000 &&
                            initialScreenSize >= 550
                            ? scrollValue <= 2
                              ? navbarToggle
                                ? "1"
                                : "500"
                              : "1"
                            : "1"
                          : currentScreenSize <= 1000 &&
                            currentScreenSize >= 550
                          ? scrollValue <= 2
                            ? navbarToggle
                              ? "1"
                              : "500"
                            : "1"
                          : "1",
                    }}
                  >
                    <h1
                      style={{
                        opacity: splashScreenComplete
                          ? "1"
                          : `${styleopacity.opacity}`,
                      }}
                    >
                      Customized skin care,
                      <br /> down to a science.
                    </h1>
                    <p
                      className="landing_page_description"
                      style={{
                        opacity: splashScreenComplete
                          ? "1"
                          : `${styleopacity.opacity}`,
                      }}
                    >
                      We've reimagined the traditional idea of a facial so that
                      we can do the thinking for you. Lay back, relax, and
                      listen to that Peruvian pan flute music. <br />
                      We'll figure out the rest.
                    </p>
                    <div
                      className="call_to_action_buttons_container"
                      style={{
                        opacity: splashScreenComplete
                          ? "1"
                          : `${styleopacity.opacity}`,
                      }}
                    >
                      <div
                        className="call_to_action_button"
                        style={{
                          opacity: splashScreenComplete
                            ? "1"
                            : `${styleopacity.opacity}`,
                        }}
                      >
                        <p
                          onClick={() => handleClickToScrollToTreatments("cta")}
                        >
                          GET STARTED NOW
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        opacity: splashScreenComplete
                          ? "1"
                          : `${styleopacity.opacity}`,
                      }}
                      className="landing_page_cta"
                      onClick={() => handleClickToScrollToTreatments("cta")}
                    >
                      <FontAwesomeIcon
                        className="landing_page_bottom_icon"
                        icon={faChevronDown}
                        style={{ opacity: lineRenderScroll ? 0 : 1 }}
                      />
                    </div>
                  </div>
                )}
              </Spring>
            </div>
          )}
        </Spring>
        <div className="splash_screen">
          <SplashScreen
            currentScreenHeight={currentScreenHeight}
            initialScreenHeight={initialScreenHeight}
          />
          {/* Need for Lighthouse Not to Throw NO_LCP Error */}
          <h1 style={{ position: "absolute", zIndex: -1 }}>Glow Labs</h1>
        </div>
      </section>
    </div>
  );
});

export default LandingPage;
