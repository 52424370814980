const FACEBOOK_COMPLETE_REGISTRATION_RESET =
  "FACEBOOK_COMPLETE_REGISTRATION_RESET";

const ACTION_FACEBOOK_COMPLETE_REGISTRATION_RESET = () => {
  return {
    type: FACEBOOK_COMPLETE_REGISTRATION_RESET
  };
};

export default ACTION_FACEBOOK_COMPLETE_REGISTRATION_RESET;
